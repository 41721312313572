<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('requests.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 dark:bg-theme-dark-2">
      <div
        class="
          flex flex-col
          sm:flex-row sm:items-end
          xl:items-start
          justify-end
        "
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="
              btn btn-outline-secondary
              dark:text-white
              w-1/2
              sm:w-auto
              mr-2
              ml-2
            "
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="
                dropdown-toggle
                btn btn-outline-secondary
                dark:text-white
                w-full
                sm:w-auto
              "
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <!-- scrollbar-hidden -->
          <table
            class="table table-report sm:mt-2"
            id="tablePrint"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <!-- <th class="whitespace-nowrap text-center" id="ignore-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />
                </th> -->
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="col.name === 'action' ? 'ignore-1' : ''"
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40">
                  <div
                    class="
                      text-black
                      dark:text-white
                      text-xs
                      whitespace-nowrap
                      mt-0.5
                    "
                  >
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <!-- <td id="ignore-4">
                  <div class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="row.id"
                      v-model="selected"
                    />
                  </div>
                </td> -->
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'createdBy') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row.requester, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'menusNeeded') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center"
                    :class="{
                      'text-theme-24': row.status == 'rejected',
                      'text-green dark:text-theme-30': row.status == 'accepted',
                      'text-theme-31': row.status === 'pending'
                    }"
                  >
                    <CheckCircleIcon
                      v-if="row.status === 'accepted'"
                      class="w-4 h-4 mr-2 ml-2"
                    />
                    <XCircleIcon
                      v-if="row.status === 'rejected'"
                      class="w-4 h-4 mr-2 ml-2"
                    />
                    <ClockIcon
                      v-if="row.status === 'pending'"
                      class="w-4 h-4 mr-2 ml-2"
                    />
                    {{
                      row.status == 'accepted'
                        ? i18n('common.approved')
                        : row.status == 'rejected'
                        ? i18n('common.rejected')
                        : i18n('common.pending')
                    }}
                  </div>
                </td>
                <td class="table-report__action w-60" id="ignore-2">
                  <div
                    class="
                      flex
                      justify-center
                      items-center
                      text-theme-24
                      space-x-4
                    "
                  >
                    <button
                      class="text-theme-24 cursor-pointer w-16"
                      v-if="row.status === 'pending'"
                      @click="rejectRequest(row)"
                    >
                      <XSquareIcon class="w-4 h-4" />
                      {{ i18n('common.reject') }}
                    </button>
                    <button
                      class="
                        text-theme-10
                        dark:text-theme-30
                        cursor-pointer
                        w-20
                      "
                      v-if="row.status === 'pending'"
                      @click="acceptRequest(row)"
                    >
                      <CheckSquareIcon class="w-4 h-4" />
                      {{ i18n('common.accept') }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="currentPage"
          :pageSize="pagination.limit"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'

export default defineComponent({
  setup() {
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableLimit,
      tableRef
    }
  },
  data() {
    return {
      filterInput: {},
      filterFields: [
        { label: this.i18n('iam.filter.username'), value: 'requester' }
      ],
      columns: [
        // {
        //   name: 'requestNumber',
        //   field: 'requestNumber',
        //   label: 'iam.fields.requestNumber',
        //   align: 'center'
        //   // required: true,
        // },
        {
          name: 'userId',
          field: 'userId',
          label: 'iam.fields.userId',
          align: 'center'
          // required: true,
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'iam.fields.userName',
          align: 'center'
          // required: true,
        },
        {
          name: 'menusNeeded',
          field: 'menusNeeded',
          label: 'iam.fields.menusNeeded',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.fields.createdAt',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'request/list/rows',
      pagination: 'request/list/pagination',
      loading: 'request/list/loading'
    }),
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.requests')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.requests')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'request/list/doFetch',
      doFetchNextPage: 'request/list/doFetchNextPage',
      doFetchPreviousPage: 'request/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'request/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'request/list/doChangePaginationPageSize',
      // doAcceptAll: 'request/list/doAcceptAllSelected',
      // doRejectAll: 'request/list/doRejectAllSelected',
      doAccept: 'request/list/doAccept',
      doReject: 'request/list/doReject'
    }),
    async doFilter(filter) {
      if (!filter || !filter.value) return
      this.filterInput = {
        [filter.key]: {
          fullName: filter.value
        }
      }
      // console.log('{{filterInput}}', this.filterInput)
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = {}
      await this.doFetchFirstPage()
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    // async doFilter(filter) {
    //   if (!filter || !filter.value) return
    //   this.filterInput.push(filter)
    //   await this.doFetchFirstPage()
    // },
    // async doResetFilter() {
    //   this.filterInput = []
    //   await this.doFetchFirstPage()
    // },
    async acceptRequest(row) {
      const data = { menusNeeded: row.menusNeeded, createdBy: row.createdBy }
      await this.doAccept({ id: row.id, data })
    },
    async rejectRequest(row) {
      const data = { menusNeeded: row.menusNeeded, createdBy: row.createdBy }
      await this.doReject({ id: row.id, data })
    },
    // async doAcceptAllSelected() {
    //   await this.doAcceptAll(this.selected)
    //   this.clearSelection()
    // },
    // async doRejectAllSelected() {
    //   await this.doRejectAll(this.selected)
    //   this.clearSelection()
    // },
    // async doDestroyAllSelected() {
    //   await this.doDestroyAll(this.selected)
    //   this.clearSelection()
    // },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        pagination: {
          firstPage: true,
          limit: 12,
          action: 'next',
          sortBy: 'desc'
        },
        filter: this.filterInput,
        orderBy: 'createdAt'
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'requestsTable', 'requests')
    },
    onDownloadPdf() {
      $h.downloadPdf('#tablePrint', 'requests', [1, 6])
    },
    onPrint() {
      print({
        printable: 'tablePrint',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 400,
        ignoreElements: ['ignore-1', 'ignore-2', 'ignore-3', 'ignore-4']
      })
    }
  }
})
</script>
<style scoped lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.dark {
  .table-report:not(.table-report--bordered):not(.table-report--tabulator) {
    td,
    th {
      background: none !important;
    }
  }
}

.form-check-input {
  &:checked {
    background-color: #d5aa10 !important;
    border-color: #d5aa10 !important;
  }
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
